@charset "UTF-8";
/*////////////////////////////////////////////////////

    .設定

////////////////////////////////////////////////////*/
/*スペース*/
/*中央寄せ $direction x：横方向 y：縦方向 xy：縦横方向*/
@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
/* ページ上部固定バナー */
.top28th-bnr {
  width: 100%;
  position: fixed;
  background-image: linear-gradient(90deg, #f6d77c, #ffe9a2 50%, #f6d77c);
  padding: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1px;
  height: 50px;
  top: 0;
  left: 0;
  z-index: 9999999;
}
@media only screen and (max-width: 767px) {
  .top28th-bnr {
    height: 30px;
  }
}
.top28th-bnr img {
  width: 80%;
  height: 100%;
  max-width: 830px;
}
@media only screen and (max-width: 767px) {
  .top28th-bnr img {
    width: 75%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .top28th-bnr .bnr-pc {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .top28th-bnr .bnr-sp {
    display: none;
  }
}

body.top .header {
  width: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 767px) {
  body.top .header {
    top: 0;
  }
}

#floting-bnr .banner {
  display: none;
  position: fixed;
  margin: 0;
  z-index: 99998;
  bottom: 50px;
}
#floting-bnr .banner img {
  width: 60px;
}
#floting-bnr .banner a {
  text-decoration: none;
}
#floting-bnr .banner-close {
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 99999;
  padding: 4px 9px 3px 9px;
  border: none;
  background-color: #ffcd50;
  border-radius: 25px;
  font-size: 17px;
  color: #0b3f71;
  cursor: pointer;
  -webkit-box-shadow: 0 0 7px #0b3f71;
  box-shadow: 0 0 7px #0b3f71;
}
@media screen and (max-width: 750px) {
  #floting-bnr {
    display: none;
  }
  #floting-bnr .banner {
    bottom: 10vh;
  }
  #floting-bnr .banner img {
    width: 50px;
  }
  #floting-bnr .banner-close {
    font-weight: bold;
    position: absolute;
    top: -12px;
    left: -12px;
  }
}