@charset "utf-8";
@use "../../common/css/variable" as vars;
@use "sass:math";

/* ページ上部固定バナー */

.top28th-bnr {
  width: 100%;
    position: fixed;
    background-image: linear-gradient(90deg, #f6d77c, #ffe9a2 50%, #f6d77c);
    padding: 0;
    text-align: center;
    font-weight: bold;
    font-size: 1px;
    height: 50px;
    top: 0;
    left: 0;
    z-index: 9999999;
    @include vars.media(sp) {
      height: 30px;
    }
    img {
      width: 80%;
      height: 100%;
      max-width: 830px;
      @include vars.media(sp) {
        width: 75%;
        max-width: 100%;
      }
    }
    .bnr-pc {
      @include vars.media(sp) {display: none;}
    }
    .bnr-sp {
      @include vars.media(tab-pc) {display: none;}
    }
}

body.top {
  .header{
    width: 100%;
    position: fixed;
    z-index: 999999;
    // top: 50px;
    top: 0;
    left: 0;
    @include vars.media(sp) {
      // top: 30px;
      top: 0;
    }
  }
}


#floting-bnr {

  .banner {
    display: none;
    position: fixed;
    margin: 0;
    z-index: 99998;
    bottom: 50px;
    // right: -2px;
    img {
      width: 60px;
    }
  }
  .banner a {
    text-decoration: none;
  }
  
  .banner-close {
    font-weight: bold;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 99999;
    padding: 4px 9px 3px 9px;
    border: none;
    background-color: #ffcd50;
    border-radius: 25px;
    font-size: 17px;
    color: #0b3f71;
    cursor: pointer;
    -webkit-box-shadow: 0 0 7px #0b3f71;
    box-shadow: 0 0 7px #0b3f71;
  }
  
  @media screen and (max-width: 750px) {
    display: none;
    .banner {
      bottom: 10vh;
      img {
        width: 50px;
      }
    }
    .banner-close {
      font-weight: bold;
      position: absolute;
      top: -12px;
      left: -12px;
    }
  }
  
  }